<template>
    <div>
        <div class="sector pb-0 pl-0 pr-0">
            <p v-if="getProfileImageError" class="custom-error custom-error--break mb-2"> {{ getProfileImageError }} </p>
            <p v-if="localError" class="custom-error custom-error--break mb-2"> {{ localError }} </p>
            <div v-if="getProfileImagePending">
                <div v-if="getProfileImageUrl" id="profileImage" class="profile__image" :style="{ backgroundImage: 'url(' + getProfileImageUrl + ')' }">
                    <span class="profile__dot"></span>
                    <button @click.prevent="editProfileImage" class="profile__black-box"><simple-line-icons icon="pencil" size="small" color="#fff" />Edit</button>
                </div>
                <div v-else id="profileImage" class="profile__image" :style="{ backgroundImage: 'url(' + require('../assets/images/profile/user.jpg') + ')' }">
                    <span class="profile__dot"></span>
                    <button @click.prevent="editProfileImage" class="profile__black-box"><simple-line-icons icon="pencil" size="small" color="#fff" />Edit</button>
                </div>
            </div>
            <div v-else class="mb-2">
                <Spinner size="medium" line-fg-color="#e91e63" />
            </div>
            <b-row v-if="editingProfileImage">
                <b-col cols="12">
                    <div class="custom-upload mb-2 ml-1">
                        <label for="fileUpload" class="custom-upload-label">
                        <simple-line-icons icon="picture" size="small" color="#504e70" />Choose file
                        </label>
                        <input @change="onFileChanged" id="fileUpload" type="file"/>
                    </div>
                </b-col>
                <b-col cols="12" class="mb-2">
                    <button @click.prevent="cancelProfileImage" class="interaction-button interaction-button--yellow"><simple-line-icons icon="minus" size="small" color="#fff" />Cancel</button>
                    <button :disabled="!getProfileImageUrl" :class="!getProfileImageUrl ? 'disabled-element' : ''" @click.prevent="deleteProfileImage" class="interaction-button interaction-button--red"><simple-line-icons icon="close" size="small" color="#fff" />Delete</button>
                    <button :disabled="!selectedFile" :class="!selectedFile ? 'disabled-element' : ''" @click.prevent="updateProfileImage" class="interaction-button interaction-button--green"><simple-line-icons icon="check" size="small" color="#fff" />Update</button>
                </b-col>
            </b-row>
            <div class="profile__navigation">
                <router-link to="/profile" class="profile__navigation-link" active-class="active-profile" exact><simple-line-icons icon="user" size="small" color="#504e70" />My profile</router-link>
                <router-link to="/my-agencies" class="profile__navigation-link" :class="(this.$route.name == 'add-agency' || this.$route.name == 'edit-agency' ? 'active-profile' : '')" active-class="active-profile" exact><simple-line-icons icon="layers" size="small" color="#504e70" />My agency</router-link>
                <router-link to="/my-events" class="profile__navigation-link" :class="(this.$route.name == 'add-event' || this.$route.name == 'edit-event') ? 'active-profile' : ''" active-class="active-profile" exact><simple-line-icons icon="calendar" size="small" color="#504e70" />My events</router-link>
                <router-link to="/my-job-offers" class="profile__navigation-link" :class="(this.$route.name == 'add-job-offer' || this.$route.name == 'edit-job') ? 'active-profile' : ''" active-class="active-profile" exact><simple-line-icons icon="briefcase" size="small" color="#504e70" />My job offers</router-link>
                <router-link to="/payments" class="profile__navigation-link" active-class="active-profile" exact><simple-line-icons icon="wallet" size="small" color="#504e70" />My payments</router-link>
                <router-link to="/subscription" class="profile__navigation-link" active-class="active-profile" exact><simple-line-icons icon="puzzle" size="small" color="#504e70" />Subscription</router-link>
                <router-link to="/settings" class="profile__navigation-link" active-class="active-profile" exact><simple-line-icons icon="equalizer" size="small" color="#504e70" />Settings</router-link>
                <button @click.prevent="signOut" type="button" class="profile__navigation-link"><simple-line-icons icon="logout" size="small" color="#504e70" />Sign out</button>
            </div>
        </div>
    </div>
</template>

<script>
import SimpleLineIcons from 'vue-simple-line'
import { mapGetters } from 'vuex'
import Spinner from 'vue-simple-spinner'
export default {
  components: {
    SimpleLineIcons,
    Spinner,
  },
  data: function () {
    return {
      editingProfileImage: false,
      selectedFile: null,
      localError: null
    }
  },
  methods: {
    signOut () {
      this.$store.dispatch('signOut')
    },
    editProfileImage () {
      this.editingProfileImage = true
    },
    cancelProfileImage () {
      this.editingProfileImage = false
      this.selectedFile = null
      if (this.getProfileImageUrl != null) {
        document.getElementById('profileImage').style.backgroundImage = 'url("' + this.getProfileImageUrl + '")'
      } else {
        document.getElementById('profileImage').style.backgroundImage = 'url("' + require('../assets/images/profile/user.jpg') + '")'
      }
    },
    onFileChanged (event) {
      if (event.target.files[0] !== undefined) {
        this.selectedFile = event.target.files[0]

        var inputFile = document.querySelector('#fileUpload')

        var selectedValue = document.querySelector('#fileUpload').value

        var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i

        var fileSize = this.selectedFile.size / 1024 / 1024 // in MB

        if (!allowedExtensions.exec(selectedValue)) {
          this.$swal({
            type: 'error',
            title: 'Invalid file extension',
            text: 'Supported file formats: .jpeg .jpg .png .gif',
            confirmButtonText: 'OK'
          })

          inputFile.value = ''

          this.selectedFile = null

          return false
        } else if (fileSize > 3) {
          this.$swal({
            type: 'error',
            title: 'File size is too large',
            text: 'Uploaded file size must not exceed 3MB',
            confirmButtonText: 'OK'
          })

          inputFile.value = ''

          this.selectedFile = null

          return false
        } else {
          // Image preview
          if (this.selectedFile) {
            var reader = new FileReader()
            reader.onload = function (e) {
              document.getElementById('profileImage').style.backgroundImage = 'url("' + e.target.result + '")'
            }
            reader.readAsDataURL(inputFile.files[0])
          }
        }
      }
    },
    updateProfileImage () {
      var file = this.selectedFile
      this.$store.dispatch('updateProfileImage', { file }).then(() => {
        this.editingProfileImage = false
      })
        .catch(error => {
          this.localError = error.message
        })
    },
    deleteProfileImage () {
      this.$store.dispatch('deleteProfileImage').then(() => {
        this.editingProfileImage = false
      })
        .catch(error => {
          this.localError = error.message
        })
    }
  },
  computed: {
    ...mapGetters([
      'getProfileImageUrl',
      'getProfileImageError',
      'getProfileImagePending'
    ])
  },
  beforeCreate () {
    this.$store.dispatch('getProfileImage')
    this.$store.dispatch('clearImageError')
  }
}
</script>
