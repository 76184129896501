<template>
    <div>
        <b-container>
            <section class="profile">
                <b-row>
                   <b-col cols="12" md="4" lg="3">
                       <ProfileNavigation />
                   </b-col>
                    <b-col cols="12" md="8" lg="9">
                        <div class="sector pl-0 pr-0">
                            <h2 class="profile__title mb-4">Settings</h2>
                            <div v-if="getUserDataPending" class="profile__content">
                                <p v-if="getError" class="custom-error mb-3"> {{ getError }} </p>
                                <p v-if="localError" class="custom-error mb-3"> {{ localError }} </p>
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <div class="profile__element">
                                            <b-row>
                                                <b-col cols="6">
                                                    <p class="profile__label">Name</p>
                                                </b-col>
                                                <b-col cols="6">
                                                    <button @click.prevent="editName(getUserName)" v-if="!editingName" class="interaction-button interaction-button--default float-right"><simple-line-icons icon="pencil" size="small" color="#504e70" />Update</button>
                                                </b-col>
                                            </b-row>
                                            <p v-if="!editingName" class="profile__value">{{ getUserName }}</p>
                                            <form @submit.prevent="updateName(newName)" v-else>
                                                <input class="form-input mb-1" v-model.trim="newName" type="text" @blur="$v.newName.$touch()" :class="{'input-error' : $v.newName.$error }" />
                                                <div class="mb-1" v-if="$v.newName.$error">
                                                    <p v-if="!$v.newName.required" class="custom-error">Name is required</p>
                                                    <p v-else-if="!$v.newName.maxLength" class="custom-error">Name must not exceed 50 characters</p>
                                                </div>

                                                <button type="submit" class="interaction-button interaction-button--green mr-1"><simple-line-icons icon="check" size="small" color="#fff" />Save</button>
                                                <button type="button" @click.prevent="cancelEditName" class="interaction-button interaction-button--yellow"><simple-line-icons icon="minus" size="small" color="#fff" />Cancel</button>
                                            </form>
                                        </div>
                                        <div class="profile__element">
                                            <b-row>
                                                <b-col cols="6">
                                                    <p class="profile__label">E-mail address</p>
                                                </b-col>
                                                <b-col cols="6">
                                                   <button @click.prevent="editEmail(getUserEmail)" v-if="!editingEmail" class="interaction-button interaction-button--default float-right"><simple-line-icons icon="pencil" size="small" color="#504e70" />Update</button>
                                                </b-col>
                                            </b-row>
                                            <p v-if="!editingEmail" v-line-clamp:24="1" class="profile__value single-line">{{ getUserEmail }}</p>

                                            <form @submit.prevent="updateEmail(newEmail)" v-else>
                                                <input v-model.trim="newEmail" type="email" @blur="$v.newEmail.$touch()" :class="{'input-error' : $v.newEmail.$error }" class="form-input mb-1" placeholder="Type new e-mail address" />
                                                <div class="mb-1" v-if="$v.newEmail.$error">
                                                    <p v-if="!$v.newEmail.required" class="custom-error">E-mail address is required</p>
                                                    <p v-else-if="!$v.newEmail.email" class="custom-error">E-mail address is invalid</p>
                                                    <p v-else-if="!$v.newEmail.maxLength" class="custom-error">E-mail address must not exceed 50 characters</p>
                                                </div>
                                                <input v-model.trim="newEmailConfirm" type="email" @blur="$v.newEmailConfirm.$touch()" :class="{'input-error' : $v.newEmailConfirm.$error }" class="form-input mb-1" placeholder="Type new e-mail address again" />
                                                <div class="mb-1" v-if="$v.newEmailConfirm.$error">
                                                    <p v-if="!$v.newEmailConfirm.confirmEmail" class="custom-error">Given e-mail addresses do not match. </p>
                                                </div>

                                                <button type="submit" class="interaction-button interaction-button--green mr-1"><simple-line-icons icon="check" size="small" color="#fff" />Save</button>
                                                <button @click.prevent="cancelEditEmail" type="button" class="interaction-button interaction-button--yellow"><simple-line-icons icon="minus" size="small" color="#fff" />Cancel</button>
                                            </form>
                                        </div>
                                    </b-col>
                                    <b-col cols="12" md="6">
                                        <div class="profile__element">
                                            <b-row>
                                                <b-col cols="6">
                                                    <p class="profile__label">Password</p>
                                                </b-col>
                                                <b-col cols="6">
                                                   <button @click.prevent="editPassword(newPassword)" v-if="!editingPassword" class="interaction-button interaction-button--default float-right"><simple-line-icons icon="pencil" size="small" color="#504e70" />Update</button>
                                                </b-col>
                                            </b-row>
                                            <span v-if="!editingPassword" class="ml-0 tip"><simple-line-icons icon="info" size="small" color="#504e70" />Set a new password for your account</span>
                                            <form v-if="editingPassword" @submit.prevent="updatePassword(newPassword)">
                                                <input v-model.trim="newPassword" type="password" @blur="$v.newPassword.$touch()" :class="{'input-error' : $v.newPassword.$error }" class="form-input mb-1" placeholder="Type new password" />
                                                <div class="mb-1" v-if="$v.newPassword.$error">
                                                    <p v-if="!$v.newPassword.required" class="custom-error">Password is required</p>
                                                    <p v-else-if="!$v.newPassword.minLength" class="custom-error">Minimal password length is 8 characters</p>
                                                </div>
                                                <input v-model.trim="newPasswordConfirm" type="password" @blur="$v.newPasswordConfirm.$touch()" :class="{'input-error' : $v.newPasswordConfirm.$error }" class="form-input mb-1" placeholder="Type new password again" />
                                                <div class="mb-1" v-if="$v.newPasswordConfirm.$error">
                                                    <p v-if="!$v.newPasswordConfirm.confirmPassword" class="custom-error">Given passwords do not match</p>
                                                </div>

                                                <button type="submit" class="interaction-button interaction-button--green mr-1"><simple-line-icons icon="check" size="small" color="#fff" />Save</button>
                                                <button @click.prevent="cancelEditPassword" type="button" class="interaction-button interaction-button--yellow"><simple-line-icons icon="minus" size="small" color="#fff" />Cancel</button>
                                            </form>
                                        </div>
                                        <div class="profile__element">
                                            <b-row>
                                                <b-col cols="6">
                                                    <p class="profile__label">Account</p>
                                                </b-col>
                                                <b-col cols="6">
                                                   <button @click.prevent="showDeleteAlert" class="interaction-button interaction-button--red float-right"><simple-line-icons icon="close" size="small" color="#fff" />Delete</button>
                                                </b-col>
                                            </b-row>
                                            <span class="ml-0 tip"><simple-line-icons icon="info" size="small" color="#504e70" />Delete your account permanently</span>
                                        </div>
                                    </b-col>
                                </b-row>
                            </div>
                            <div v-else class="profile__content">
                                <Spinner size="medium" line-fg-color="#e91e63" />
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </section>
        </b-container>
    </div>
</template>

<script>
import SimpleLineIcons from 'vue-simple-line'
import ProfileNavigation from '@/components/ProfileNavigation.vue'
import { mapGetters } from 'vuex'
import Spinner from 'vue-simple-spinner'
import {
  email,
  required,
  minLength,
  maxLength,
  sameAs
} from 'vuelidate/lib/validators'
export default {
  components: {
    SimpleLineIcons,
    ProfileNavigation,
    Spinner
  },
  data: function () {
    return {
      editingName: false,
      name: '',
      newName: '',
      editingEmail: false,
      newEmail: '',
      newEmailConfirm: '',
      editingPassword: false,
      newPassword: '',
      newPasswordConfirm: '',
      localError: null
    }
  },
  validations: {
    newName: {
      required: required,
      maxLength: maxLength(50)
    },
    newEmail: {
      required: required,
      email: email,
      maxLength: maxLength(50)
    },
    newEmailConfirm: {
      confirmEmail: sameAs('newEmail')
    },
    newPassword: {
      required: required,
      minLength: minLength(8)
    },
    newPasswordConfirm: {
      confirmPassword: sameAs('newPassword')
    }
  },
  methods: {
    editName (name) {
      this.editingName = true
      this.newName = name
    },
    cancelEditName () {
      this.editingName = false
    },
    updateName (name) {
      this.$v.newName.$touch()
      if (!this.$v.newName.$invalid) {
        this.$store.dispatch('updateName', { name }).then(() => {
          this.editingName = false
          this.newName = ''
        })
          .catch(error => {
            this.localError = error.message
          })
      }
    },
    editEmail (email) {
      this.editingEmail = true
    },
    cancelEditEmail () {
      this.editingEmail = false
      this.newEmail = ''
      this.newEmailConfirm = ''
    },
    updateEmail (email) {
      this.$v.newEmail.$touch()
      if (!this.$v.newEmail.$invalid) {
        this.$store.dispatch('updateEmail', { email }).then(() => {
          this.editingEmail = false
          this.newEmail = ''
          this.newEmailConfirm = ''
        })
          .catch(error => {
            this.localError = error.message
          })
      }
    },
    editPassword (password) {
      this.editingPassword = true
    },
    cancelEditPassword () {
      this.editingPassword = false
      this.newPassword = ''
      this.newPasswordConfirm = ''
    },
    updatePassword (password) {
      this.$v.newPassword.$touch()
      if (!this.$v.newPassword.$invalid) {
        this.$store.dispatch('updatePassword', { password }).then(() => {
          this.editingPassword = false
          this.newPassword = ''
          this.newPasswordConfirm = ''
        })
          .catch(error => {
            this.localError = error.message
          })
      }
    },
    showDeleteAlert () {
      this.$swal({
        type: 'warning',
        title: 'Warning',
        text: 'Are you sure you want to delete your account? All your information will be permanently deleted and inaccessible.',
        showCancelButton: true,
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
        confirmButtonColor: '#f1352e',
        cancelButtonColor: '#13b81b',
        focusCancel: true
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch('deleteAccount', { profileImage: this.getUserProfile[0].profileImage })
        }
      })
    }
  },
  computed: {
    ...mapGetters([
      'getUserDataPending',
      'getError',
      'getUserName',
      'getUserEmail',
      'getUserProfile'
    ])
  },
  beforeCreate () {
    this.$store.dispatch('getUserProfile')
    this.$store.dispatch('getUserData')
  }
}
</script>
